import Loader from "@/components/layout/Loader";
import { getLanguage } from "@/utils/language";
import { isKnownRedirect, runRedirects } from "@/utils/redirects.utils";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function NotFound() {
  const location = useLocation();
  const language = getLanguage();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (isKnownRedirect(location.pathname)) runRedirects(location.pathname);
    else setLoading(false);
  }, [location]);

  if (isLoading) {
    return <Loader />;
  }

  return language === "en" ? (
    <div className="flex h-screen flex-col items-center justify-center">
      <h1 className="mb-4 font-bold">404 - Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  ) : (
    <div className="flex h-screen flex-col items-center justify-center">
      <h1 className="mb-4 font-bold">404 - Non trouvé</h1>
      <p>Désolé, la page que vous recherchez n'existe pas.</p>
    </div>
  );
}
