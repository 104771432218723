import PageHeader from "@/components/layout/PageHeader";
import PageSection from "@/components/layout/PageSection";
import Shell from "@/components/layout/default/Shell";
import companyData from "@/config/companyData";
import { getLanguage } from "@/utils/language";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

export default function Q3() {
  const { t } = useTranslation(["common", "faqs"]);

  const P = ({ children }: PropsWithChildren) => (
    <p className="my-5">{children}</p>
  );

  return (
    <Shell>
      <PageHeader
        title={t("faqs:q3")}
        breadcrumbs={[
          {
            name: t("common:faqs"),
            href: "/help",
            current: false,
          },
        ]}
      />

      <PageSection>
        {getLanguage() === "fr" ? (
          <P>
            Si un véhicule occupe votre stationnement après la fin de sa
            réservation, veuillez contacter notre service client au{" "}
            <a href={companyData.phoneNumber}>
              {companyData.formattedPhoneNumber}
            </a>
            . Notre équipe de support interviendra rapidement pour résoudre la
            situation.
          </P>
        ) : (
          <P>
            If a vehicle occupies your parking lot after its reservation has
            ended, please contact our customer service department at{" "}
            <a href={companyData.phoneNumber}>
              {companyData.formattedPhoneNumber}
            </a>
            . Our support team will intervene promptly to resolve the situation.
          </P>
        )}
      </PageSection>
    </Shell>
  );
}
