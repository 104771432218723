import PageHeader from "@/components/layout/PageHeader";
import PageSection from "@/components/layout/PageSection";
import Shell from "@/components/layout/default/Shell";
import HelpPanel from "@/containers/HelpPanel";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function FaqsList() {
  const { t } = useTranslation(["faqs"]);

  const [helpPanelOpen, setHelpPanelOpen] = useState(false);

  const faqs = [
    {
      question: t("faqs:q1"),
      path: "q1",
    },
    {
      question: t("faqs:q2"),
      path: "q2",
    },
    {
      question: t("faqs:q3"),
      path: "q3",
    },
    {
      question: t("faqs:q4"),
      path: "q4",
    },
    {
      question: t("faqs:q5"),
      path: "q5",
    },
  ];

  return (
    <Shell>
      <PageHeader title={t("faqs:title")} />

      <PageSection>
        <div className="rounded-lg bg-white pt-6 shadow ring-1 ring-black ring-opacity-5">
          <p className="px-5 pb-5 text-base leading-7 text-gray-600 sm:px-8">
            <Trans
              t={t}
              i18nKey="faqs:description"
              components={[
                <span
                  className="cursor-pointer font-semibold text-rapide-600 transition-all hover:text-rapide-600 hover:underline"
                  onClick={() => setHelpPanelOpen(true)}
                />,
              ]}
            />
          </p>

          <div className="border-t border-t-silver-900">
            <ul className="divide-y divide-silver-900">
              {faqs.map((faq) => (
                <li>
                  <Link
                    to={`/help/${faq.path}`}
                    key={faq.question}
                    className="group flex cursor-pointer items-center justify-between px-5 py-3 text-base font-semibold leading-7 text-simple-900 transition-all hover:text-rapide-600 sm:px-8"
                  >
                    <div className="flex-1">{faq.question}</div>
                    <ChevronRightIcon
                      className={`h-[40px] w-[40px] flex-none pl-5 transition-all`}
                    />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </PageSection>

      <HelpPanel open={helpPanelOpen} onClose={() => setHelpPanelOpen(false)} />
    </Shell>
  );
}
