import { FCP_BankAccount } from "@/@types/user";
import Badge from "@/components/Badge";
import { DataList } from "@/components/DataList";
import AlertSimple from "@/components/alert/AlertSimple";
import FormError from "@/components/form/layout/FormError";
import PageHeader from "@/components/layout/PageHeader";
import PageSection from "@/components/layout/PageSection";
import Shell from "@/components/layout/default/Shell";
import { Button } from "@/components/ui/button";
import { CPException } from "@/models/exceptions/CPException";
import {
  useDeleteBankAccountMutation,
  useGetConnectedAccountQuery,
  useUpdateBankAccountMutation,
} from "@/services/connect";
import { useGenerateKycLinkQuery } from "@/services/kyc";
import { formatStripeAddress } from "@/utils/formatter";
import { ShieldCheckIcon } from "@heroicons/react/20/solid";
import { useQueryClient } from "@tanstack/react-query";
import { Fragment, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

interface ExtendedBankAccount extends FCP_BankAccount {
  deleting: boolean;
  settingAsDefault: boolean;
}

export default function AccountPayment() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation(["common", "account", "validation"]);

  const { data: connectedAccount } = useGetConnectedAccountQuery();

  const { mutateAsync: updateBankAccountAsync } =
    useUpdateBankAccountMutation();

  const { mutateAsync: deleteBankAccountAsync } =
    useDeleteBankAccountMutation();

  const [dataList, setDataList] = useState<
    { title: string; description?: string; data: React.ReactNode }[]
  >([]);

  const [bankAccountsList, setBankAccountsList] = useState<
    ExtendedBankAccount[]
  >([]);

  const [shouldGenerateLink, setShouldGenerateLink] = useState(false);

  const [error, setError] = useState<string | undefined>();

  const { data: kycLink, error: kycLinkError } = useGenerateKycLinkQuery({
    refreshUrl: window.location.origin + "/payment",
    returnUrl: window.location.origin + "/payment?idv=success",
    enabled: shouldGenerateLink,
  });

  useEffect(() => {
    // Set representative information list

    const company = connectedAccount?.accountData?.company;
    const companyName = company?.name || "";
    const businessType = connectedAccount?.accountData?.business_type || "";
    const address = company?.address
      ? formatStripeAddress(company.address)
      : "";
    const persons = connectedAccount?.accountData?.persons || [];

    const data: { title: string; data: React.ReactNode }[] = [];

    if (businessType) {
      data.push({
        title: t("account:organizationType"),
        data: businessType ? t(`account:${businessType}`) : "",
      });
    }

    if (companyName) {
      data.push({
        title: t("account:organizationName"),
        data: companyName,
      });
    }

    if (persons.length > 0) {
      data.push({
        title: t("account:representativeName"),
        data: persons.map((person) => (
          <ul key={person.first_name}>
            <li>
              {person.first_name} {person.last_name}
            </li>
          </ul>
        )),
      });
    }

    if (address) {
      data.push({
        title: t("account:address"),
        data: address,
      });
    }

    setDataList(data);

    // Set bank accounts list

    const bankAccounts = connectedAccount?.accountData?.bankAccounts;

    if (bankAccounts) {
      const data = bankAccounts
        .map((bankAccount) => ({
          ...bankAccount,
          deleting: false,
          settingAsDefault: false,
        }))
        .sort((b) => (b.default_for_currency ? -1 : 1));

      setBankAccountsList(data);
    }
  }, [connectedAccount]);

  useEffect(() => {
    if (kycLink) {
      window.location.href = kycLink;
    }
  }, [kycLink]);

  useEffect(() => {
    if (kycLinkError) {
      setShouldGenerateLink(false);
    }
  }, [kycLinkError]);

  async function setBankAccountAsDefault(id: string) {
    setBankAccountsList((prev) =>
      prev.map((b) => (b.id === id ? { ...b, settingAsDefault: true } : b))
    );

    try {
      await updateBankAccountAsync({
        id,
        options: { default_for_currency: true },
      });

      queryClient.invalidateQueries({ queryKey: ["connectedAccounts"] });
    } catch (error) {
      // if (error instanceof CPException) {
      // } else {
      // }

      setBankAccountsList((prev) =>
        prev.map((b) => (b.id === id ? { ...b, settingAsDefault: false } : b))
      );
    }
  }

  async function deleteBankAccount(id: string) {
    setBankAccountsList((prev) =>
      prev.map((b) => (b.id === id ? { ...b, deleting: true } : b))
    );

    try {
      await deleteBankAccountAsync({ id });
      queryClient.invalidateQueries({ queryKey: ["connectedAccounts"] });
    } catch (error) {
      if (error instanceof CPException) {
        setError(error.message);
      } else {
        setError(t("validation:genericError"));
      }

      setBankAccountsList((prev) =>
        prev.map((b) => (b.id === id ? { ...b, deleting: false } : b))
      );
    }
  }

  let required = []; // Everything from stripe except "external_account"
  let errors: React.ReactNode[] = [];
  let requiresBankAccount = false; // "external_account"

  const currently_due =
    connectedAccount?.accountData?.requirements?.currently_due || [];
  const filtered_currently_due = currently_due.filter(
    (r) => r !== "external_account"
  );
  required.push(...filtered_currently_due);
  const eventually_due =
    connectedAccount?.accountData?.requirements?.eventually_due || [];
  const filtered_eventually_due = eventually_due.filter(
    (r) => r !== "external_account"
  );
  required.push(...eventually_due);
  const past_due = connectedAccount?.accountData?.requirements?.past_due || [];
  const filtered_past_due = past_due.filter((r) => r !== "external_account");
  required.push(...past_due);

  if (required.some((r) => r === "external_account"))
    requiresBankAccount = true;
  required = required.filter((r) => r !== "external_account");

  const displayEventuallyDue = filtered_eventually_due.length > 0;
  const displayCurrentlyDue = filtered_eventually_due.length > 0;
  const displayPastDue = filtered_past_due.length > 0;

  /* 

    User will need to provide the following information to be able to receive payments:
    
    - Tax ID
    - Verification document

    Only if it is a business

    https://stripe.com/docs/connect/required-verification-information#CA-full-company--card_payments%7Ctransfers

  */

  const requiredLabels = [];

  if (
    required.find((v) => v.startsWith("company.")) ||
    required.find((v) => v.startsWith("business_"))
  ) {
    requiredLabels.push(t("account:kycCompanyInformation"));
  }

  if (required.find((v) => v.startsWith("owners."))) {
    requiredLabels.push(t("account:kycOwnersInformation"));
  }

  if (required.find((v) => v.startsWith("person_"))) {
    requiredLabels.push(t("account:kycPersonInformation"));
  }

  if (required.find((v) => v.startsWith("directors."))) {
    requiredLabels.push(t("account:kycDirectorsInformation"));
  }

  if (required.find((v) => v.endsWith("proof_of_liveness"))) {
    requiredLabels.push(t("account:kycProofOfLiveness"));
  }

  if (connectedAccount?.accountData?.requirements?.errors) {
    connectedAccount?.accountData.requirements.errors.map((error) => {
      if (error.reason)
        errors.push(<p className="w-[75%] pl-5 italic">{error.reason}</p>);
    });
  }

  const RequiredList = (
    <ul className="mt-2 pl-3">
      {requiredLabels.map((i) => (
        <li className="list-disc" key={i}>
          {i}
        </li>
      ))}
    </ul>
  );

  return (
    <Shell>
      <PageHeader title={t("common:payment")} />

      {error && (
        <div className="mb-5">
          <FormError title={error} />
        </div>
      )}

      <div className="h-full divide-y divide-silver-800 bg-white">
        <PageSection>
          {requiresBankAccount ? (
            <div className="mb-5">
              <AlertSimple
                title={t("account:kycRequiresBankAccount")}
                type="warning"
                link={{
                  onClick: () => navigate("/onboarding/link-bank-account"),
                  text: t("common:resolve"),
                }}
              />
            </div>
          ) : null}

          {displayEventuallyDue && !displayPastDue && !displayCurrentlyDue ? (
            <div className="mb-5">
              <AlertSimple
                title={t("account:kycInformationRequiredEventuallyDue")}
                content={
                  <Fragment>
                    {RequiredList}
                    {errors.flat()}
                  </Fragment>
                }
                type="warning"
                link={{
                  onClick: () => setShouldGenerateLink(true),
                  text: t("common:resolve"),
                }}
              />
            </div>
          ) : null}

          {displayCurrentlyDue && !displayPastDue ? (
            <div className="mb-5">
              <AlertSimple
                title={t("account:kycInformationRequiredCurrentlyDue")}
                content={
                  <Fragment>
                    {RequiredList}
                    {errors.flat()}
                  </Fragment>
                }
                type="warning"
                link={{
                  onClick: () => setShouldGenerateLink(true),
                  text: t("common:resolve"),
                }}
              />
            </div>
          ) : null}

          {displayPastDue ? (
            <div className="mb-5">
              <AlertSimple
                title={t("account:kycInformationRequiredPastDue")}
                content={
                  <Fragment>
                    {RequiredList}
                    {errors.flat()}
                  </Fragment>
                }
                type="error"
                link={{
                  onClick: () => setShouldGenerateLink(true),
                  text: t("common:resolve"),
                }}
              />
            </div>
          ) : null}

          <DataList
            title={t("account:representativeInformation")}
            subtitle={
              <Trans
                t={t}
                i18nKey="account:representativeInformationDescription"
                components={[
                  <Link to="/help/q2" className="text-rapide-600" key="kyc" />,
                ]}
              />
            }
            datalist={dataList}
          />

          <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-3">
            <div />
            <div className="mt-3 md:col-span-2">
              <Button
                loading={shouldGenerateLink}
                disabled={shouldGenerateLink}
                onClick={() => setShouldGenerateLink(true)}
              >
                {t("common:modify")}
              </Button>
            </div>
          </div>
        </PageSection>

        <PageSection>
          <DataList
            title={t("account:bankAccounts")}
            subtitle={t("account:bankAccountsDescription")}
            noResultLabel={t("account:noBankAccounts")}
            datalist={
              bankAccountsList.map(
                ({
                  id,
                  bank_name,
                  routing_number,
                  last4,
                  default_for_currency,
                  settingAsDefault,
                  deleting,
                }) => ({
                  data: (
                    <div className="items-center justify-between sm:flex">
                      <div>
                        <strong className="text-sm">{bank_name} </strong>
                        <div>{routing_number + " **** " + last4}</div>
                        {default_for_currency && (
                          <div className="mt-1">
                            <Badge variant="success">
                              {t("account:defaultBankAccount").toUpperCase()}
                            </Badge>
                          </div>
                        )}
                      </div>

                      <div className="mt-3 sm:mt-0">
                        {!default_for_currency && (
                          <Button
                            size="sm"
                            variant="outline"
                            color="secondary"
                            className="mr-3"
                            onClick={() => setBankAccountAsDefault(id)}
                            loading={settingAsDefault}
                            disabled={settingAsDefault || deleting}
                          >
                            {t("common:setAsDefault")}
                          </Button>
                        )}

                        <Button
                          size="sm"
                          variant="outline"
                          color="destructive"
                          onClick={() => deleteBankAccount(id)}
                          loading={deleting}
                          disabled={
                            default_for_currency || settingAsDefault || deleting
                          }
                        >
                          {t("common:delete")}
                        </Button>
                      </div>
                    </div>
                  ),
                })
              ) || [
                {
                  title: t("account:noBankAccounts"),
                },
              ]
            }
          />

          <div className="grid grid-cols-1 gap-x-8 gap-y-4 md:grid-cols-3">
            <div />
            <div className="mt-3 md:col-span-2">
              <Button
                size="sm"
                className="mt-1"
                onClick={() => navigate("/onboarding/link-bank-account")}
              >
                {t("account:linkBankAccount")}
              </Button>
            </div>
          </div>
        </PageSection>

        <PageSection>
          <div className="flex-col items-center px-4 py-5 text-center sm:p-6">
            <div className="mb-1 inline-block rounded-full bg-rapide-600 p-2">
              <ShieldCheckIcon className="w-[24px] text-white" />
            </div>

            <div>
              <h3 className="text-base font-semibold leading-6 text-simple-900">
                {t("account:dataIsSafe")}
              </h3>
              <div className="text-sm text-gray-600">
                {t("account:dataIsSafeDescription")}
              </div>
            </div>

            <div className="mt-3 text-sm leading-6">
              <a
                href="https://stripe.com/docs/security/stripe"
                className="font-semibold text-rapide-600 hover:text-indigo-500"
                target="_blank"
                rel="noreferrer noopener"
              >
                {t("account:dataIsSafeLink")}
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          </div>
        </PageSection>
      </div>
    </Shell>
  );
}
