import PageHeader from "@/components/layout/PageHeader";
import PageSection from "@/components/layout/PageSection";
import Shell from "@/components/layout/default/Shell";
import companyData from "@/config/companyData";
import { getLanguage } from "@/utils/language";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

export default function Q4() {
  const { t } = useTranslation(["common", "faqs"]);

  const P = ({ children }: PropsWithChildren) => (
    <p className="my-5">{children}</p>
  );

  return (
    <Shell>
      <PageHeader
        title={t("faqs:q4")}
        breadcrumbs={[
          {
            name: t("common:faqs"),
            href: "/help",
            current: false,
          },
        ]}
      />

      <PageSection>
        {getLanguage() === "fr" ? (
          <P>
            La gestion et la communication auprès des automobilistes est inclus
            dans nos services, veuillez contacter notre service client par
            courriel :{" "}
            <a href="mailto:aide@clicknpark.com">aide@clicknpark.com</a> ou par
            téléphone au{" "}
            <a href={companyData.phoneNumber}>
              {companyData.formattedPhoneNumber}
            </a>
          </P>
        ) : (
          <P>
            Management and communication with motorists is included in our
            services, please contact our customer service department by e-mail:{" "}
            <a href="mailto:help@clicknpark.com">help@clicknpark.com</a>
            or by telephone at{" "}
            <a href={companyData.phoneNumber}>
              {companyData.formattedPhoneNumber}
            </a>
          </P>
        )}
      </PageSection>
    </Shell>
  );
}
