import Head from "@/containers/Head";
import { NavigationProvider } from "@/contexts/NavigationContext";
import { NotificationProvider } from "@/contexts/NotificationContext";

import i18next from "@/i18n";
import { runRedirects } from "@/utils/redirects.utils";
import { getBifrostEmail } from "@/utils/storage";
import mixpanel from "mixpanel-browser";
import posthog from "posthog-js";
import { useEffect } from "react";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";

export default function Root() {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("language")) {
      const language = searchParams.get("language");
      if (language === "fr" || language === "en") {
        i18next.changeLanguage(language);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    console.log("pathname", pathname);
    runRedirects(pathname);
  }, [pathname]);

  useEffect(() => {
    // Track pageview
    if (!getBifrostEmail()) {
      // @ts-ignore
      mixpanel?.track_pageview(pathname);
      posthog.capture("$pageview");
    }

    // Adjust body class based on route
    if (pathname.includes("onboarding") || pathname.includes("parks/add"))
      document.body.classList.add("fullscreen");
    else document.body.classList.remove("fullscreen");
  }, [pathname]);

  return (
    <NavigationProvider>
      <NotificationProvider>
        <Head />
        <Outlet />
      </NotificationProvider>
    </NavigationProvider>
  );
}
