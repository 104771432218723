import PageHeader from "@/components/layout/PageHeader";
import PageSection from "@/components/layout/PageSection";
import Shell from "@/components/layout/default/Shell";
import { getLanguage } from "@/utils/language";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

export default function Q2() {
  const { t } = useTranslation(["common", "faqs"]);

  const H1 = ({ children }: PropsWithChildren) => (
    <h1 className="mt-8 text-2xl font-semibold">{children}</h1>
  );

  const H2 = ({ children }: PropsWithChildren) => (
    <h2 className="mt-8 text-lg font-semibold">{children}</h2>
  );

  const P = ({ children }: PropsWithChildren) => (
    <p className="my-5">{children}</p>
  );

  const UL = ({ children }: PropsWithChildren) => (
    <ul className="my-3 pl-3">{children}</ul>
  );

  const LI = ({ children }: PropsWithChildren) => (
    <li className="list-disc py-1">{children}</li>
  );

  return (
    <Shell>
      <PageHeader
        title={t("faqs:q2")}
        breadcrumbs={[
          {
            name: t("common:faqs"),
            href: "/help",
            current: false,
          },
        ]}
      />

      <PageSection>
        {getLanguage() === "fr" ? (
          <>
            <H1>Vérification de votre compte de Locateur</H1>

            <P>
              Si vous êtes locateur d’espaces de stationnement sur l’application
              clicknpark, il se peut que nous vous demandions de fournir des
              informations telles que votre nom légal, votre adresse de
              résidence, votre date de naissance ou une pièce d'identité à des
              fins de vérification. Ces informations sont requises par les lois
              applicables dans le cadre d'un processus appelé « Know Your
              Customer » (KYC). Dans le cas où des informations inexactes sont
              fournies, les autorisations de votre compte peuvent être limitées
              et vous risquez de ne pas pouvoir recevoir vos paiements de
              location.
            </P>

            <P>
              Certaines de ces informations vous seront demandées lors de
              l’inscription de votre premier stationnement. Il est possible
              qu’en fonction du volume de réservations, d’autres informations
              deviennent requises. Nous communiquerons alors avec vous. Vous
              recevrez un e-mail et des notifications dans votre tableau de
              bord, avec des instructions pour savoir comment procéder.
            </P>

            <H2>Exigences relatives à la procédure KYC</H2>

            <P>
              « Know Your Customer » est une exigence réglementaire visant à
              protéger notre communauté et la société en général contre la
              fraude, et à prévenir le blanchiment d'argent et le financement du
              terrorisme. Presque toutes les entreprises autorisées à émettre
              des paiements aux particuliers doivent se conformer à cette
              procédure. En d'autres termes, cette procédure exige que nous
              sachions qui gère le compte clicknpark et qui est payé afin de
              nous conformer à la réglementation applicable.
            </P>

            <H2>Pourquoi est-ce important ?</H2>

            <P>
              Si certaines informations sont incorrectes ou incomplètes, vos
              versements ou même la mise en ligne de votre stationnement peuvent
              être suspendus jusqu'à ce que nous soyons en mesure de vérifier
              votre compte. Celui-ci peut même faire l'objet d'un signalement
              indiquant qu'il est potentiellement frauduleux.
            </P>

            <H2>Comment utilisons-nous vos informations ?</H2>

            <P>
              La confidentialité de vos données et votre sécurité sont une
              priorité pour nous. Nous prenons les mesures de sécurité
              appropriées pour protéger les informations personnelles que nous
              vous demandons. Nous vérifions les informations que vous
              fournissez uniquement dans le but de protéger davantage votre
              compte et de prévenir les délits financiers dans le respect des
              règles KYC. Pour en savoir plus, consultez notre politique de
              confidentialité.[Note: mettre hyperlien]
            </P>

            <H2>Quelles sont les informations requises ?</H2>

            <P>
              Les informations exactes que nous demandons peuvent varier selon
              la réglementation en vigueur dans votre zone géographique. Il
              s'agit généralement des informations suivantes :
            </P>

            <UL>
              <LI>
                <strong>Votre nom légal complet :</strong> c'est le nom qui
                figure sur vos pièces d'identité et documents officiels. Il peut
                être différent de votre nom de profil sur clicknpark. Votre nom
                de profil ne sera pas modifié, même s'il est différent du nom
                légal que vous nous indiquez.
              </LI>
              <LI>
                <strong>L'adresse de votre domicile :</strong> il s'agit de
                l'adresse physique où vous vivez. Elle peut être différente de
                l'adresse du ou des stationnements que vous proposez sur
                clicknpark. Cette information est utilisée pour confirmer votre
                identité. Elle ne sera pas affichée sur votre annonce ni
                partagée avec vos locataires.
              </LI>
              <LI>
                <strong>Votre date de naissance</strong>
              </LI>
              <LI>
                <strong>
                  Votre lieu de naissance, votre citoyenneté ou nationalité :
                </strong>{" "}
                ces informations ne seront utilisées que pour confirmer votre
                identité. Nous pouvons également vous demander de fournir une
                pièce d’identité.
              </LI>
            </UL>

            <H2>Quand faut-il fournir des informations professionnelles ?</H2>

            <P>
              Si vous possédez une entreprise enregistrée, une société de
              personnes ou une société par actions, et si votre compte est lié
              de quelque manière que ce soit à une ou plusieurs de ces
              entreprises, nous pouvons être amenés à vérifier les informations
              qui les concernent afin de nous conformer à la procédure KYC.
            </P>

            <P>
              Nous pouvons également être tenus de confirmer certaines
              informations telles que votre identifiant fiscal, ainsi que les
              pièces d'identité des personnes associées à votre compte de
              Locateur, notamment celles qui gèrent les réservations et les
              paiements sur votre compte de Locateur, et les propriétaires,
              gestionnaires ou directeurs de toute entreprise qui y est liée.
            </P>

            <H2>
              Quand les informations sur le bénéficiaire effectif sont-elles
              requises ?
            </H2>

            <P>
              Un bénéficiaire effectif est une personne qui détient ou contrôle
              directement ou indirectement plus de 25 % de l'entreprise. Si une
              entreprise enregistrée ou une entité juridique est associée à
              votre compte, il vous sera demandé de fournir des informations sur
              les personnes qui possèdent ou contrôlent l'entreprise. Ces
              informations sont également requises dans le cadre du processus de
              vérification d'identité des clients (la procédure « KYC ») afin de
              prévenir les délits financiers.
            </P>
          </>
        ) : (
          <>
            <H1>Verifying your Host account</H1>
            <P>
              If you rent a parking spot in clicknpark, we may ask you to
              provide information such as your legal name,address, date of
              birth, or government ID for verification. These details are
              required by applicable law as part of a process called Know Your
              Customer (KYC). If the information provided is not correct, your
              account permissions could be limited, and you may not be able to
              receive the payments you would otherwise be entitled to.
            </P>
            <P>
              This information will need to be provided when you register your
              first parking. Depending on the volume of reservation. we may
              require additional information from you, in which case we will let
              you know. You'll receive an email and notifications in your
              dashboard, with instructions on how to provide what's required.
            </P>
            <H2>KYC requirements</H2>
            <P>
              KYC is a regulatory requirement to help protect our community from
              fraud and to prevent money laundering and terrorist financing.
              Nearly every large company licensed to issue payments to
              individuals has to comply with KYC—in other words, we’re required
              to know who’s operating the clicknpark account, and who’s getting
              paid, in order to conform to this requirement.
            </P>
            <H2>Why this is important</H2>
            <P>
              If any information is incorrect or incomplete, your payments or
              the online listing of your parking may be interrupted until we can
              verify your account, and your account could even be flagged as
              potentially fraudulent.
            </P>
            <H2>How we use your information</H2>
            <P>
              At clicknpark, your privacy and safety are a priority. We take all
              appropriate measures to protect your personal information. We
              verify the information you provide solely in order to help keep
              your account secure and to help prevent financial crimes while
              respecting KYC rules. Read more in our Privacy Policy.
            </P>
            <H2>What is the required information</H2>
            <P>
              The exact information we request may vary according to the
              applicable law in your jurisdiction. It will typically include:
            </P>
            <UL>
              <LI>
                Your full legal name: The name that appears on a government ID
                or official document. It may differ from your clicknpark profile
                name. What you enter as your legal name won’t change your
                profile name.
              </LI>
              <LI>
                Your residential address: This is the physical address where you
                live, which may differ from your parking listing address(es).
                It’s used to confirm your identity and won’t be shown on your
                listing or shared with guests.
              </LI>
              <LI>Your date of birth.</LI>
              <LI>
                Your place of birth, citizenship, and/or nationality: These will
                only be used to confirm your identity.
              </LI>
            </UL>

            <H2>When do you need to provide business information?</H2>

            <P>
              If you have a registered business, partnership or company, and if
              your account is in any way connected to one or more businesses,
              the KYC process may require us to also verify your business
              information.
            </P>

            <P>
              We may also be required to confirm information like your Tax ID as
              well as request government IDs for people associated with your
              Host account, such as those who manage reservations and payments
              using your Host account and the owners, managers, or directors of
              any businesses related to it. When beneficial owner information is
              required.
            </P>

            <P>
              A beneficial owner is anyone who directly or indirectly owns or
              controls more than 25% of a business. If there’s a registered
              business or legal entity associated with your account, you’ll be
              asked to provide information for these people who own or control
              the company. This information is also required by KYC in order to
              prevent financial crimes.
            </P>
          </>
        )}
      </PageSection>
    </Shell>
  );
}
