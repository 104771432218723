import PageHeader from "@/components/layout/PageHeader";
import PageSection from "@/components/layout/PageSection";
import Shell from "@/components/layout/default/Shell";
import { getLanguage } from "@/utils/language";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

export default function Q5() {
  const { t } = useTranslation(["common", "faqs"]);

  const P = ({ children }: PropsWithChildren) => (
    <p className="my-5">{children}</p>
  );

  return (
    <Shell>
      <PageHeader
        title={t("faqs:q5")}
        breadcrumbs={[
          {
            name: t("common:faqs"),
            href: "/help",
            current: false,
          },
        ]}
      />

      <PageSection>
        {getLanguage() === "fr" ? (
          <P>
            Les paiements sont traités automatiquement et transférés à votre
            compte dans un délai de 5 à 7 jours ouvrables après la fin de chaque
            réservation. Vous pouvez consulter les détails de vos paiements dans
            votre espace “Mon compte”.
          </P>
        ) : (
          <P>
            Payments are processed automatically and transferred to your account
            within 5 to 7 working days after the end of each booking. You can
            view your payment details in your “My account” area.
          </P>
        )}
      </PageSection>
    </Shell>
  );
}
