const redirects = [
  { from: "auth", to: "/" },
  { from: "/help/kyc", to: "/help/q2" },
  { from: "/help/pricing", to: "/help/q1" },
];

export function isKnownRedirect(pathname: string) {
  return redirects.some((redirect) => pathname.includes(redirect.from));
}

export function runRedirects(pathname: string) {
  const redirect = redirects.find((redirect) =>
    pathname.includes(redirect.from)
  );

  if (redirect)
    window.location.href = `${window.location.origin}${redirect.to}`;
}
